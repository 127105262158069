import { useTheme } from '@emotion/react';
import { Button } from '@mui/material';
import { useStoreActions } from 'easy-peasy';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

function Logout() {
  const logout = useStoreActions((a) => a.logout);

  const theme = useTheme();

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  function onLogoutClick() {
    enqueueSnackbar('Logging out.. :)');

    logout({
      onSuccess: () => {
        history.replace('/');
        enqueueSnackbar('Logged out :)', { variant: 'success' });
      },
      onError: (err) => {
        enqueueSnackbar('Error logging out: ' + err, { variant: 'error' });
      }
    });
  }

  return (
    <div>
      <Button variant='contained' onClick={onLogoutClick}>
        Bye
      </Button>
    </div>
  );
}

export default Logout;
