import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import useCurrentUser from '../hooks/useCurrentUser';

function Nav() {
  const history = useHistory();

  const { currentUserLoading, currentUserError, currentUser } =
    useCurrentUser();

  // @TODO conditional rendering of elements in the nav
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='static'>
          <Toolbar>
            {/* <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
              style={{ color: 'white' }}
            >
              <MenuIcon />
            </IconButton> */}
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackIosNewIcon style={{ color: 'white' }} />
            </IconButton>

            <Link to={'/'}>Home___</Link>

            {currentUser ? (
              <>
                <Link to={'/logout'}>Logout___</Link>
              </>
            ) : (
              <>
                <Link to={'/login'}>Login___</Link>
              </>
            )}

            <Typography
              variant='h6'
              component='div'
              sx={{ flexGrow: 1 }}
              style={{ color: 'white' }}
            >
              {/* {currentUser ? currentUser.username : 'No user'} */}
            </Typography>
            {/* <Button color='inherit'>Login</Button> */}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

export default Nav;
