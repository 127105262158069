import { useTheme } from '@emotion/react';
import { Button, TextField, Typography } from '@mui/material';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

function Login() {
  const theme = useTheme();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const login = useStoreActions((a) => a.login);

  const currentUser = useStoreState((s) => s.currentUser);

  const usernameInput = useRef();
  const passwordInput = useRef();

  function onLoginClick() {
    const _username = usernameInput.current.value;
    const _password = passwordInput.current.value;

    if (!_username || !_password) {
      return;
    }

    const dataToSubmit = {
      username: _username,
      password: _password
    };

    login({
      dataToSubmit,
      onSuccess: (message) => {
        enqueueSnackbar(message, { variant: 'success' });
        history.replace('/');
      },
      onError: (error) => enqueueSnackbar(error, { variant: 'error' })
    });
  }

  // const testValue = useStoreState((s) => s.testValue);
  // const fetchTestValue = useStoreActions((a) => a.fetchTestValue);
  // function onTestValueClick() {
  //   fetchTestValue({
  //     onError: (error) => enqueueSnackbar(error, { variant: 'error' }),
  //     onSuccess: (_testValue) =>
  //       enqueueSnackbar(JSON.stringify(_testValue), { variant: 'success' })
  //   });
  // }

  // console.log('testValue', testValue);

  return (
    <div>
      <Typography
        variant='h3'
        onClick={() =>
          enqueueSnackbar('I love my kitty cat <3', { variant: 'success' })
        }
      >
        Login
      </Typography>

      <TextField
        type='text'
        label='Username'
        inputProps={{
          ref: usernameInput
        }}
      />

      <TextField
        type='password'
        label='Password'
        inputProps={{
          ref: passwordInput
        }}
      />

      <Button variant='contained' onClick={onLoginClick}>
        Login
      </Button>
      {/* <Button variant='contained' onClick={onTestValueClick}>
        Test Value: {JSON.stringify(testValue)}
      </Button> */}
    </div>
  );
}

export default Login;
