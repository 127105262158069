import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import theme from './theme.js';
import { ThemeProvider } from '@mui/material';
import { StoreProvider } from 'easy-peasy';
import store from './store';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarProvider } from 'notistack';
import { createRef, useEffect } from 'react';

function Asd({ children }) {
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={5000}
      ref={notistackRef}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
      )}
      preventDuplicate={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

ReactDOM.render(
  <StoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <Asd>
        <App />
      </Asd>
    </ThemeProvider>
  </StoreProvider>,
  document.getElementById('root')
);
