import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

function useCurrentUser() {
  const fetchCurrentUser = useStoreActions((a) => a.fetchCurrentUser);
  const currentUser = useStoreState((s) => s.currentUser);
  const currentUserLoading = useStoreState((s) => s.currentUserLoading);
  const currentUserError = useStoreState((s) => s.currentUserError);

  useEffect(() => {
    fetchCurrentUser({
      onSuccess() {
        console.log();
      }
    });
  }, []);

  return {
    currentUser,
    currentUserLoading,
    currentUserError
  };
}

export default useCurrentUser;
