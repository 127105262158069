import { action, createStore, thunk } from 'easy-peasy';
import API_ROOT from './API_ROOT';

const store = createStore({
  currentUser: null,
  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),

  currentUserLoading: false,
  setCurrentUserLoading: action((state, payload) => {
    state.currentUserLoading = payload;
  }),

  currentUserError: false,
  setCurrentUserError: action((state, payload) => {
    state.currentUserError = payload;
  }),

  login: thunk((actions, { dataToSubmit, onError, onSuccess }) => {
    fetch(`${API_ROOT}/login`, {
      method: 'POST',
      body: JSON.stringify(dataToSubmit),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          onError(res.error);
        } else {
          // actions.setCurrentUser(res);
          actions.fetchCurrentUser();
          onSuccess('Logged in :)');
        }
      });
  }),

  logout: thunk((actions, { onError, onSuccess }) => {
    fetch(`${API_ROOT}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          onError(res.error);
        } else {
          actions.setCurrentUser(null);
          onSuccess();
        }
      });
  }),

  fetchCurrentUser: thunk((actions) => {
    actions.setCurrentUserLoading(true);
    fetch(`${API_ROOT}/currentUser`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          // console.log('Error: ', res);
          // actions.setCurrentUserError(res.error);
        } else {
          // console.log('fetchCurrentUser', res.currentUser);
          actions.setCurrentUser(res.currentUser);
        }
      })
      .catch((err) => {
        actions.setCurrentUserError(err.message);
      })
      .finally(() => {
        actions.setCurrentUserLoading(false);
      });
  })

  // testValue: null,
  // setTestValue: action((state, payload) => {
  //   console.log('?', payload);
  //   state.testValue = payload;
  // }),
  // fetchTestValue: thunk((actions, { onError, onSuccess }) => {
  //   fetch(`${API_ROOT}/testValue`, {
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json'
  //     },
  //     credentials: 'include'
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.error) {
  //         onError(res.error);
  //       } else {
  //         actions.setTestValue(res);
  //         onSuccess(res);
  //       }
  //     });
  // })

  // logout: thunk((actions, { dataToSubmit, onError, onSuccess }) => {
  //   fetch(`${API_ROOT}/logout`, {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     credentials: 'include'
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.error) {
  //         onError(
  //           Array.isArray(res.message) ? res.message.join(' ') : res.message
  //         );
  //       } else {
  //         actions.setCurrentUser(null);
  //         onSuccess('Logged out');
  //       }
  //     });
  // })
});

export default store;
