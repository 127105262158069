import { CircularProgress } from '@mui/material';

function Loader() {
  return (
    <center>
      <CircularProgress />
    </center>
  );
}

export default Loader;
