import { createTheme } from '@mui/material';

export default createTheme({
  props: {
    MuiUseMediaQuery: {
      noSsr: true
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif"
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#FFC0CB'
    },
    secondary: {
      main: '#00ff00'
    }

    // success: {
    //   main: green[500],
    //   ligth: green[200]
    // },

    // warning: {
    //   main: orange[500],
    //   ligth: orange[200]
    // }
  }
});
