import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import NotLoggedInHome from './Components/Routes/NotLoggedInHome';

import Login from './Components/Routes/Login';
import Logout from './Components/Routes/Logout';
import Nav from './Components/Nav';
import createUser from './Components/Routes/CreateUser';
import usersProfile from './Components/Routes/UsersProfile';
import galleries from './Components/Routes/Galleries';
import campaigns from './Components/Routes/Campaigns';
import singleGallery from './Components/Routes/SingleGallery';
import singleCampaign from './Components/Routes/SingleCampaign';
import editGallery from './Components/Routes/EditGallery';
import editCampaign from './Components/Routes/EditCampaign';
import createGallery from './Components/Routes/CreateGallery';
import createCampaign from './Components/Routes/CreateCampaign';
import deleteGallery from './Components/Routes/DeleteGallery';
import deleteCampaign from './Components/Routes/DeleteCampaign';
import { useStoreActions, useStoreState } from 'easy-peasy';
import useCurrentUser from './hooks/useCurrentUser.js';
import { lazy, Suspense } from 'react';
import Loader from './Components/Loader';

const LoggedInHome = lazy(() => import('./Components/Routes/LoggedInHome'));

function NotLoggedInSwitch() {
  return (
    <Switch>
      <Route exact={true} path={'/'} component={NotLoggedInHome} />
      <Route exact={true} path={'/login'} component={Login} />
    </Switch>
  );
}

function LoggedInSwitch() {
  return (
    <Switch>
      <Route
        exact={true}
        path={'/'}
        component={() => (
          <Suspense fallback={<Loader />}>
            <LoggedInHome />
          </Suspense>
        )}
      />
      <Route exact={true} path={'/logout'} component={Logout} />
      <Route exact={true} path={'/create-user'} component={createUser} />
      <Route exact={true} path={'/:username'} component={usersProfile} />
      <Route exact={true} path={'/:username/galleries'} component={galleries} />
      <Route exact={true} path={'/:username/campaigns'} component={campaigns} />
      <Route
        exact={true}
        path={'/:username/galleries/:galleryId'}
        component={singleGallery}
      />
      <Route
        exact={true}
        path={'/:username/campaigns/:campaignId'}
        component={singleCampaign}
      />
      <Route
        exact={true}
        path={'/:username/edit-gallery/:galleryId'}
        component={editGallery}
      />
      <Route
        exact={true}
        path={'/:username/edit-campaign/:campaignId'}
        component={editCampaign}
      />
      <Route
        exact={true}
        path={'/:username/create-gallery'}
        component={createGallery}
      />
      <Route
        exact={true}
        path={'/:username/create-campaign'}
        component={createCampaign}
      />
      <Route
        exact={true}
        path={'/:username/delete-gallery/:galleryId'}
        component={deleteGallery}
      />
      <Route
        exact={true}
        path={'/:username/delete-campaign/:campaignId'}
        component={deleteCampaign}
      />
    </Switch>
  );
}

function App() {
  const { currentUserLoading, currentUserError, currentUser } =
    useCurrentUser();
  // console.log(currentUser, currentUserError);
  return (
    <Router basename='/'>
      <Nav />
      {currentUserLoading ? (
        <Loader />
      ) : currentUserError ? (
        'error...'
      ) : currentUser ? (
        <LoggedInSwitch />
      ) : (
        <NotLoggedInSwitch />
      )}
    </Router>
  );
}

export default App;
